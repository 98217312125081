import { Routes } from '@angular/router';

export const contentPvo: Routes = [
  {
    path: 'add-handbook',
    loadChildren: () => import('../../components/add-handbook/add-handbook.module').then(m => m.AddHandbookModule),
    data: {
      breadcrumb: "Qo'llanma qo'shish"
    }
  },
  {
    path: 'rejected-field',
    loadChildren: () => import('../../components/rejected-field/rejected-field.module').then(m => m.RejectedFieldModule),
    data: {
      breadcrumb: "Rad etilgan"
    }
  },
  {
    path: 'being-checked',
    loadChildren: () => import('../../components/being-checked/being-checked.module').then(m => m.BeingCheckedModule),
    data: {
      breadcrumb: "Ariza holati"
    }
  },
  {
    path: 'my-handbooks',
    loadChildren: () => import('../../components/handbooks/handbooks.module').then(m => m.HandbooksModule),
    data: {
      breadcrumb: "Mening qo'llanmalarim"
    }
  },
];
// PublishingDepModule
export const contentAdmin: Routes = [
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Foydalanuvchilar"
    }
  },
  {
    path: 'handbooks',
    loadChildren: () => import('../../components/handbooks/handbooks.module').then(m => m.HandbooksModule),
    data: {
      breadcrumb: "Qo'llanmalar"
    }
  },
  {
    path: 'check-ah',
    loadChildren: () => import('../../components/check-ah/check-ah.module').then(m => m.CheckAhModule),
    data: {
      breadcrumb: "Tekshirish"
    }
  }
];
export const contentPublishingDep: Routes = [
  {
    path: 'publishing-dep',
    loadChildren: () => import('../../components/publishing-dep/publishing-dep.module').then(m => m.PublishingDepModule),
    data: {
      breadcrumb: "Noshirlik"
    }
  }
]
export const contentKafedra: Routes = [
  {
    path: 'fakultet',
    loadChildren: () => import('../../components/kafedra/kafedra.module').then(m => m.KafedraModule),
    data: {
      breadcrumb: "Fakultet"
    }
  }
]