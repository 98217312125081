<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Expertlar Xulosasi</h5>
                </div>
                <div class="card-body tab2-card">
                    <div class="red" *ngIf="tmp.error">{{tmp.error}}</div>
                    <form [formGroup]="accountForm" (submit)="onSubmit()" class="needs-validation user-add" novalida>
                        <div class="form-group row">
                            <label for="validationCustom0" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Ishlanma muallifi
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="ishnmu" 
                                    placeholder="Ishlanma muallifi" 
                                    type="text"
                                    id="validationCustom0"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom1s2d" class="col-xl-3 col-md-4">
                                Ishlanma turi
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="ishlt" id="validationCustom1s2d">
                                    <option disabled selected value> -- tanlang -- </option>
                                    <option value="O'quv qo'llanma">O'quv qo'llanma</option>
                                    <option value="Uslubiy qo'llanma">Uslubiy qo'llanma</option>
                                    <option value="Uslubiy ko'rsatma">Uslubiy ko'rsatma</option>
                                    <option value="Uslubiy tavsiyanoma">Uslubiy tavsiyanoma</option>
                                    <option value="Ma'lumotlar to'plami">Ma'lumotlar to'plami</option>
                                    <option value="Ma'ruzalar kursi">Ma'ruzalar kursi</option>
                                    <option value="Ma'ruzalar to'plami">Ma'ruzalar to'plami</option>
                                    <option value="Mashqlar to'plami">Mashqlar to'plami</option>
                                    <option value="Lug'at">Lug'at</option>
                                    <option value="Dayjest">Dayjest</option>
                                    <option value="Boshqa">Boshqa</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom0t" class="col-xl-3 col-md-4">
                                Ishlanma nomi
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="ishln" 
                                    placeholder="Ishlanma nomi" 
                                    type="text"
                                    id="validationCustom0t"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4">
                                Ishlanma hajmi(betlar va b.t)
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="ishh" 
                                    placeholder="Ishlanma hajmi(betlar va b.t)" 
                                    type="text"
                                    id="validationCustom1"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">
                                Ichki taqriz
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="icht" 
                                    placeholder="Ichki taqriz" 
                                    type="text"
                                    id="validationCustom2"
                                >
                            </div>
                        </div>
                        <ngx-dropzone 
                            id="ngx-dropzone_1" 
                            [maxFileSize]="20000000" 
                            class="dropzone-custom dropzone dropzone-primary" 
                            (change)="onSelectFile($event, 'ichtfile')" 
                            accept="application/pdf"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files.ichtfile;" [removable]="true" (removed)="onRemoveFile(f, 'ichtfile')">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="form-group row">
                            <label for="validationCustom2t" class="col-xl-3 col-md-4">
                                Tashqi taqriz
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="tasht" 
                                    placeholder="Tashqi taqriz" 
                                    type="text"
                                    id="validationCustom2t"
                                >
                            </div>
                        </div>
                        <ngx-dropzone 
                            id="ngx-dropzone_2" 
                            [maxFileSize]="20000000" 
                            class="dropzone-custom dropzone dropzone-primary" 
                            (change)="onSelectFile($event, 'tashtfile')" 
                            accept="application/pdf"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files.tashtfile;" [removable]="true" (removed)="onRemoveFile(f, 'tashtfile')">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="form-group row">
                            <label for="validationCustom3" class="col-xl-3 col-md-4">
                                Reja bo'yicha yoki rejadan tashqari
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="rbrt" 
                                    placeholder="Reja bo'yicha yoki rejadan tashqari" 
                                    type="text"
                                    id="validationCustom3"
                                >
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="validationCustom4" class="col-xl-3 col-md-4">
                                Kafedra yig'ilishi bayoni nomeri
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="kybn" 
                                    placeholder="Kafedra yig'ilishi bayoni nomeri" 
                                    type="text"
                                    id="validationCustom4"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom6" class="col-xl-3 col-md-4">
                                 Kafedra yig'ilishi bayoni sanasi
                            </label>
                            <div class="col-md-8">
                                 <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="kybs" #f="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                                class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-dropzone 
                            id="ngx-dropzone_3" 
                            [maxFileSize]="20000000" 
                            class="dropzone-custom dropzone dropzone-primary" 
                            (change)="onSelectFile($event, 'kybfile')" 
                            accept="application/pdf"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files.kybfile;" [removable]="true" (removed)="onRemoveFile(f,'kybfile')">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="form-group row">
                            <label for="validationCustom4t" class="col-xl-3 col-md-4">
                                Fakultet yig'ilishi bayoni nomeri
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="fybn" 
                                    placeholder="Fakultet yig'ilishi bayoni nomeri" 
                                    type="text"
                                    id="validationCustom4t"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom6" class="col-xl-3 col-md-4">
                                 Fakultet yig'ilishi bayoni sanasi
                            </label>
                            <div class="col-md-8">
                                 <div class="input-group input-grp-p">
                                    <input class="datepicker-here form-control digits" ngbDatepicker
                                        formControlName="fybs" #fa="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn calendar" (click)="fa.toggle()" type="button"><i
                                                class="fa fa-calendar"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ngx-dropzone 
                            id="ngx-dropzone_4" 
                            [maxFileSize]="20000000" 
                            class="dropzone-custom dropzone dropzone-primary" 
                            (change)="onSelectFile($event, 'fybfile')" 
                            accept="application/pdf"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files.fybfile;" [removable]="true" (removed)="onRemoveFile(f,'fybfile')">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>

                        <div class="form-group row">
                            <label for="validationCustom7" class="col-xl-3 col-md-4">
                                Xorijiy adabiyotlar ulushi(%da)
                            </label>
                            <div class="col-md-8">
                                <input 
                                    class="form-control"
                                    formControlName="xau" 
                                    placeholder="Xorijiy adabiyotlar ulushi(%da)" 
                                    type="text"
                                    id="validationCustom7"
                                >
                            </div>
                        </div>
                        <ngx-dropzone 
                            id="ngx-dropzone_5" 
                            [maxFileSize]="20000000" 
                            class="dropzone-custom dropzone dropzone-primary" 
                            (change)="onSelectFile($event, 'uslfile')" 
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing(eslatma: word fayl yuklanadi).</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files.uslfile;" [removable]="true" (removed)="onRemoveFile(f,'uslfile')">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="red fileError" *ngIf="tmp.fileError">
                          {{tmp.fileError}}
                        </div>
                        <div class="pull-left">
                            <button type="submit" class="btn btn-primary" [disabled]="accountForm.invalid">Jo'natish</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loadingFile.loadingFile">
    <circle-progress
      [percent]="tmp.loadingFile.progressFileAll+tmp.loadingFile.progressFile"
      [radius]="1.2"
      [outerStrokeWidth]="0.35"
      [innerStrokeWidth]="0.35"
      [space] = "-0.35"
      [outerStrokeColor]="'#007bff'"
      [innerStrokeColor]="'#e7e8ea'"
      [titleFontSize]= "0.5"
      [unitsFontSize]= "0.5"
      [showSubtitle] = "false"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [responsive]="true"
    ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>