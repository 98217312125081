import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../shared/service/data.service';
import { FirebaseService } from '../../shared/service/firebase.service';
import { AuthService } from '../../shared/service/auth.service';
import { RenderComponent } from './render.component';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import firebase from "firebase/app";
import "firebase/database";
import { DatePipe } from '@angular/common';

interface tmp{
  loading: boolean;
  emptyPage: boolean;
}
@Component({
  selector: 'app-being-checked',
  templateUrl: './being-checked.component.html',
  styleUrls: ['./being-checked.component.scss'],
  providers: [DatePipe]
})

export class BeingCheckedComponent {
  public products = []
  tmp: tmp = {loading: true, emptyPage: false};
  homeTypes;
  source = [];
  user: any;
  constructor(
    private dataService: DataService,
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    authService.user.subscribe(user=>{
      this.getSource(0,  user.id)
    })
  }
  getSource(i, token){
      this.firebaseService.getDoc('/ouq/handbooks/beingchecked/'+token)
      .then(snapshot=>{
        if (snapshot.exists()) {
          this.genTable(snapshot.val());
          this.source = snapshot.val();
        }else{
          this.tmp.loading = false;
          this.tmp.emptyPage = true;
        }
      }).catch(error=>{
        if (i<10) {
          setTimeout(()=>{
            this.getSource((i+1), token)
          },1000)
        }else{
          alert('#632 error ' + error);
        }
      })
  }
  genTable(source){
      (Object.keys(source)).forEach(async(res, idx, sss)=>{
          Object.keys(source[res]).forEach(key=>{
            if (key.indexOf('file') > 0) {
              var arr = '<div class="file_download">'
              source[res][key].forEach((file)=>{  
                arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              arr += '</div>'
              source[res][key] = arr;
            }
          })
          // if (source[res]['created']) {
          //   source[res]['created'] = this.datePipe.transform((new Date(source[res]['created'])), 'HH:mm dd-MM-yyyy');
          // }
          this.products.push(source[res]);
        if (sss.length === (idx+1)) {
          this.tmp.loading = false;
        }
      })
  }
  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {
      status: {
        title: 'Holati',
        type: 'custom',
        renderComponent: RenderComponent
      },
      // created: {
      //   title: 'Yuklangan sanasi'
      // },
      ishnmu: {
        title: 'Ishlanma muallifi',
      },
      ishlt: {
        title: 'Ishlanma turi'
      },
      ishln: {
        title: 'Ishlanma nomi'
      },
      ishh: {
        title: 'Ishlanma hajmi(betlar va b.t)'
      },
      icht: {
        title: 'Ichki taqriz'
      },
      ichtfile: {
        title: 'Ichki taqriz faylari',
        type: 'html'
      },
      tasht: {
        title: 'Tashqi taqriz'
      },
      tashtfile: {
        title: 'Tashqi taqriz faylari',
        type: 'html'
      },
      rbrt: {
        title: 'Reja bo\'yicha yoki rejadan tashqari'
      },
      kybn: {
        title: 'Kafedra yig\'ilishi bayoni nomeri'
      },
      kybs: {
        title: 'Kafedra yig\'ilishi bayoni sanasi'
      },
      kybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html'
      },
      fybn: {
        title: 'Fakultet yig\'ilishi bayoni nomeri'
      },
      fybs: {
        title: 'Fakultet yig\'ilishi bayoni sanasi'
      },
      fybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html'
      },
      xau: {
        title: 'Xorijiy adabiyotlar ulushi(%da)'
      },
      uslfile: {
        title: 'faylar',
        type: 'html'
      }
    },
  };
  ngOnInit() {
  }
  deleteOldFiles(rowData, i){
    const storageRef = firebase.storage().ref();
    Object.keys(rowData).forEach(rowKey=>{
      if ((rowKey.indexOf('file') > 0) && rowData[rowKey]){
        rowData[rowKey].forEach(file=>{
          const desertRef = storageRef.child('ouq/'+ 'handbooks' + '/' + file.name)
          desertRef.delete().then(() => {
            console.log('File deleted successfully newData')
          }).catch((error) => {
            console.log('Uh-oh, an error occurred!')
            if (i<10) {
              setTimeout(()=>{
                this.deleteOldFiles(rowData, (i+1));
              }, 2000)
            }
          })
        })
      };
    })

  }
  onDeleteConfirm(event){
    this.firebaseService.removeDoc(`ouq/handbooks/beingchecked/${event.data.token}/${event.data.added_id}`).then(()=>{
      Object.keys(this.source).forEach(key=>{
        if (this.source[key].added_id === event.data.added_id) {
          this.deleteOldFiles(this.source[key], 0)
        }
      })
      alert('Foydalanuvchi muvaffaqiyatli ochirildi!')
      setTimeout(()=>{
        event.confirm.resolve();
      },2000)
    }).catch(error=>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!')
    })
  }
}
