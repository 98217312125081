import {Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { InjiService } from '../../shared/service/inji.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import firebase from "firebase/app";
import "firebase/database";

@Component({
    selector: 'custom-component',
    template: `
	    <div (click)="fileUpload()" *ngIf="!tmp.loading" class="file_download">
	    	<img src='assets/images/icon/update-file.png'>
	    </div>
        <ngx-dropzone 
            id="ngx-dropzone_render_mx{{rowData.added_id}}" 
            [maxFileSize]="20000000" 
            class="dropzone-custom dropzone dropzone-primary" 
            (change)="onSelectFile($event)" 
            >
            <ngx-dropzone-label>
                <div class="dz-message needsclick">
                    <i class="fa fa-cloud-upload"></i>
                    <h4 class="mb-0 f-w-600">Файлларни бу ерга ташланг ёки юклаш учун босинг.</h4>
                </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemoveFile(f)">
                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
		<div *ngIf="tmp.loading">
			<div class="lds-dual-ring"></div>
		</div>
    `,
    styles: [`
    	.dropzone{ display:none;}
    	.d-flex{justify-content:center;}
		.lds-dual-ring {
		  position: relative;
		  display: inline-block;
		  width: 40px;
		  height: 40px;
		}
		.lds-dual-ring:after {
		  width: 32px;
		  height: 32px;
		  border-radius: 50%;
		  border: 3px solid #fff;
		  border-color: #007bff transparent #007bff transparent;
		}
		.file_download{
			display: block;
			cursor: pointer;
		}
    `]
})
export class UpdateFileRenComponent implements OnInit  {
		// .lds-dual-ring-percent{
		// 	position: absolute;
		//     width: 100%;
		//     height: 100%;
		//     display: flex;
		//     justify-content: center;
		//     align-items: center;
		//     left: 4px;
		//     top: 5px;
		//     font-size: 8px;
		// }
  //       <div>
		//     <circle-progress	
		//       [percent]="tmp.loadingFile.progressFileAll+tmp.loadingFile.progressFile"
		// 	  [radius]="0.4"
		// 	  [outerStrokeWidth]="0.12"
		// 	  [innerStrokeWidth]="0.12"
		// 	  [space] = "-0.12"
		// 	  [outerStrokeColor]="'#007bff'"
		// 	  [innerStrokeColor]="'#e7e8ea'"
		// 	  [titleFontSize]= "0.17"
		// 	  [unitsFontSize]= "0.17"
		// 	  [showSubtitle] = "false"
		// 	  [animation]="true"
		// 	  [animationDuration]="300"
		// 	  [startFromZero]="false"
		// 	  [responsive]="true"
		//     ></circle-progress>
		// </div>
		/////////////
	    // <div class="d-flex flex-nowrap text-white">
	    //   <button type="button" class="btn btn-info" (click)="fileUpload()">
	    //     <i class="fa fa-arrow-right"></i>
	    //   </button>
	    // </div>
			    // <div class="lds-dual-ring">
			    // 	<div class="lds-dual-ring-percent" *ngIf="tmp.loadingFile.loadingFile">{{tmp.loadingFile.progressFileAll+tmp.loadingFile.progressFile}}%</div>
			    // </div>
    rowData: any;
    updatedData:any;
    tmp = {
		error: "", 
		loading: false, 
		lastAdded_Id: '',  
		fileError: '', 
		loadingFile: {
		  loadingFile: false,
		  progressFile: 0,
		  progressFileAll: 0
	    },
	};

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(private InjiService: InjiService){
    }
    ngOnInit(){
    }
    fileUpload(){
    	document.getElementById(`ngx-dropzone_render_mx${this.rowData.added_id}`).click();
   	// this.rowData.ishnmi = 'test test test';
    // this.InjiService.updateRowSubjects.next(this.rowData);
    }
    files: File[] = [];
    toggleHover(e){}
    onSelectFile(event) {	
      this.tmp.loading = true;
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason==='type') {
           this.tmp.fileError = 'fayl turlarini yuklash mumkin: application/msword'
           return;
        }
        if (event.rejectedFiles[0].reason==='size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
      // this.accountForm.controls['file'].setValue(this.files);
      this.tmp.loadingFile.loadingFile = true;
      this.monitorUpload(this.files, 0, []);
    }
	onRemoveFile(event) {
	      this.files.splice(this.files.indexOf(event), 1);
	      // this.accountForm.controls['file'].setValue(this.files);
	}
	fileType(name){
	    if (name.indexOf('jpeg') !== -1) {
	      return ".jpeg" 
	    }else if(name.indexOf('jpg') !== -1){
	      return ".jpg"
	    }else if(name.indexOf('png') !== -1){
	      return ".png"
	    }else if(name.indexOf('pdf') !== -1){
	      return ".pdf"
	    }else if(name.indexOf('docx') !== -1){
	      return ".docx"
	    }else if(name.indexOf('doc') !== -1){
	      return ".doc"
	    }else{
	      return "";
	    }
    }
	monitorUpload(files, index, filesUrl) {
	    var fileItem = files[index];
	    const storageRef = firebase.storage().ref();
		var random = '_' + (Math.floor(Math.random()*1000)).toString();
	    var fileSaveName = replaceAll((fileItem.name + random), ".", "")+this.fileType(fileItem.name);

	    // [STATE_CHANGEDRT storage_upload_handle_error]
	    // Create the file metadata
	    var metadata = {
	      contentType: fileItem.type
	    };
	    // Upload file and metadata to the object 'images/mountains.jpg'
	    var uploadTask = storageRef.child('ouq/'+ 'handbooks' + '/' + fileSaveName).put(fileItem, metadata);

	    // Listen for state changes, errors, and completion of the upload.
	    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
	      (snapshot) => {
	        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
	        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
	        this.tmp.loadingFile.progressFile = progress/files.length;
	        switch (snapshot.state) {
	          case firebase.storage.TaskState.PAUSED: // or 'paused'
	            break;
	          case firebase.storage.TaskState.RUNNING: // or 'running'
	            break;
	        }
	      }, 
	      (error) => {
	        // A full list of error codes is available at
	        // https://firebase.google.com/docs/storage/web/handle-errors
	        switch (error.code) {
	          case 'storage/unauthorized':
	            console.log('storage/unauthorized')
	            break;
	          case 'storage/canceled':
	            console.log('storage/canceled')
	            // User canceled the upload
	            break;
	          case 'storage/unknown':
	            console.log('storage/unknown');
	            // Unknown error occurred, inspect error.serverResponse
	            break;
	        }
	      }, 
	      () => {
	        // Upload completed successfully, now we can get the download URL
	        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
	          this.tmp.loadingFile.progressFileAll += this.tmp.loadingFile.progressFile;
	          filesUrl.push({name: fileSaveName, url: downloadURL})
	          if (files.length === (index+1)) {
	            this.tmp.loadingFile.loadingFile = false;
	            this.tmp.loading = true;
	            // this.accountForm.value.file = filesUrl;
	            // this.saveFormToDb(this.accountForm.value);
               	this.rowData.uslfile = filesUrl;
			    this.InjiService.updateRowSubjects.next({action:'updateFile', rowData: this.rowData, filesUrl:filesUrl});
	          }
	          else{
	            this.monitorUpload(files, (index+1), filesUrl);
	          }
	        });
	      }
	    );
	    // [END storage_upload_handle_error]
	  }
}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }