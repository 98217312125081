import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../shared/service/data.service';
import { FirebaseService } from '../../../shared/service/firebase.service';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  tizim;
  tmp = {error: "", loading: false};
  pvoKaf;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private firebaseService: FirebaseService
  ) {
    this.createAccountForm();
    this.createPermissionForm();
    this.tizim = dataService.tizim;
    dataService.pvoKaf$.subscribe(res=>{
      this.pvoKaf = res;
    })
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      patronymic: [''],
      kafed: ['', Validators.required],
      fakul: ['', Validators.required],
    })
  }
  tanKafedra(){
    return (this.tizim.filter(res => res.fakultet === this.accountForm.value.fakul))[0];
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }
  ngOnInit() {
  }
  get f(){
    return this.accountForm.controls;
  }
  onAdd(event){
    var value = this.accountForm.value
    this.tmp.loading = true;
    if (this.pvoKaf.find(res=> (res.fname.replace(/ /g,'') === value.fname.replace(/ /g,'')) && (res.lname.replace(/ /g,'') === value.lname.replace(/ /g,'')) && ((res.patronymic?res.patronymic:'').replace(/ /g,'') === (value.patronymic?value.patronymic:'').replace(/ /g,'')))) {
      this.tmp.loading = false;
      return this.tmp.error="Bunday ism va sharifli o'qituvchi mavjud!";
    }
    this.firebaseService.incDb('lastId/pvoIns')
     .then((res)=>{
      this.firebaseService.getDoc('lastId/pvoIns').then((snapshot) => {
          if (snapshot.exists()) {
            value['added_id'] = snapshot.val().last_id.toString();
            this.firebaseService.setDoc('pvoIns/'+snapshot.val().last_id.toString(), value)
            .then(()=>{
              this.accountForm.reset();
              this.tmp.loading = false;
              alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
              this.tmp.error = ''
            }).catch(error=>{
              console.error(error)
            });
          }else{
            alert("No data available");
          }
      }).catch((error) => {
         alert(error);
      });
    }).catch(error =>{
      alert(error)
    });
  }
}
