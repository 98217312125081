import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../shared/service/data.service';
import { InjiService } from '../../shared/service/inji.service';
import { FirebaseService } from '../../shared/service/firebase.service';
import { UpdateFileRenComponent } from '../publishing-dep/update-file-ren.component';
import { RejectRenComponent } from '../publishing-dep/reject.component';
import { ResendComponent } from './resend.component';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { AuthService } from '../../shared/service/auth.service';
import { DatePipe } from '@angular/common';

import firebase from "firebase/app";
import "firebase/database";

interface tmp{
  loading: boolean;
  emptyPage: boolean;
}
@Component({
  selector: 'app-rejected-field',
  templateUrl: './rejected-field.component.html',
  styleUrls: ['./rejected-field.component.scss'],
  providers: [DatePipe]
})
export class RejectedFieldComponent {
  dataSource: LocalDataSource = new LocalDataSource();
  tmp: tmp = {loading: true, emptyPage: false};
  homeTypes;
  source;
  constructor(
    private dataService: DataService,
    private firebaseService: FirebaseService,
    private injiService: InjiService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    // dataService.pvoKaf$.subscribe(res=>{
    //   this.products = res;
    //   this.tmp.loading = false;
    // })
    authService.user.subscribe(user=>{
      this.getSource(0, user.id)
    })
  }
  ngOnInit() {
    this.injiService.updateRowSubjects.subscribe( dd => {
      switch (dd['action']) {
        case "updateFile":
          this.onFileUpdate(dd);
          break;
        case "rejectedRow":
          this.rejectedRow(dd['data'], dd['rejectedMessage']);
          break; 
        case "resend":
          this.resend(dd['data']);
          break;        
        default:
          // code...
          break;
      }
    });
  }
  resend(rowData){
     Object.keys(this.source).forEach(key=>{
       if (this.source[key].added_id === rowData.added_id) {
         Object.keys(rowData).forEach(rowKey=>{
            if ((rowKey.indexOf('file') > 0)) rowData[rowKey] = this.source[key][rowKey];
          })
         if (rowData['rejected']&&rowData['rejected'].length)rowData.rejected = this.source[key].rejected;
         rowData['status'] = (rowData['rejected'][(rowData['rejected'].length)-1]).who;
         this.updateTable(rowData, key);
       }
     })
     console.log('resend2', rowData)
  }
  rejectedRow(rowData, rejectedMessage){  
      var sourceKey;
      Object.keys(this.source).forEach(key=>{
        if (this.source[key].added_id === rowData.added_id) {
            sourceKey = key;
            if (!this.source[key]['rejected']) { this.source[key]['rejected'] = []; }
            this.source[key]['rejected'].push(rejectedMessage);
        }
      })
      this.updateTable(rowData, sourceKey)
  }
  updateTable(rowData, sourceKey){
      this.firebaseService.setDoc(`ouq/handbooks/beingchecked/${rowData.token}/${rowData.added_id}`, this.source[sourceKey]).then(()=>{
        this.firebaseService.removeDoc(`ouq/handbooks/rejected/${rowData.token}/${rowData.added_id}`).then(()=>{
          this.dataSource['data'].forEach((row, index)=>{
            if(row.added_id===rowData.added_id){
              delete this.dataSource['data'][index];
            }
          });
          delete this.source[sourceKey];
          this.dataSource.refresh();
        }).catch(error=>{
          alert('#535 Nimadur hato ketti yana bir bor urinib ko\'ring!')
        })
      }).catch(error=>{
        alert('#534 Nimadur hato ketti yana bir bor urinib ko\'ring!')
      });
  }
  deleteOldFiles(files, i, index, toNewSource){
    // console.log('files ', files)
    const storageRef = firebase.storage().ref();
    if (files) {
      files.forEach(file=>{
        const desertRef = storageRef.child('ouq/'+ 'handbooks' + '/' + file.name)
        desertRef.delete().then(() => {
          // this.source[index].file = [];
            // this.source[index].uslfile = filesUrl;
          this.source[index].uslfile = toNewSource
          console.log('File deleted successfully newData')
        }).catch((error) => {
          console.log('Uh-oh, an error occurred!')
          if (i<10) {
            setTimeout(()=>{
              this.deleteOldFiles(files, (i+1), index, toNewSource);
            }, 2000)
          }else{
            this.dataSource.refresh();
          }
        })
      })
    }

  }
  onFileUpdate(dd) {
    console.log(8, dd)
    Object.keys(this.source).forEach((index)=>{
        if (this.source[index].added_id === dd['rowData'].added_id) {
          this.firebaseService.updateDoc(`/ouq/handbooks/rejected/${dd['rowData'].token}/${dd['rowData'].added_id}`, {uslfile: dd['filesUrl']}).then(async()=>{
            this.deleteOldFiles(this.source[index].uslfile, 0, index, dd['filesUrl']);
            await this.dataSource['data'].forEach(bbb=>{
              if (bbb.added_id === dd['rowData'].added_id) {
                 if (dd['filesUrl']) {
                    var row = dd['rowData'];
                    var arr = '<div class="file_download">'
                    dd['filesUrl'].forEach((file, idxFile, sssFile)=>{  
                      arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
                    })
                    arr += '</div>'
                    row.uslfile = arr;
                    bbb = row;
                 }
              }
            })
            this.dataSource.refresh();
          }).catch(error=>{
            alert('#532 Nimadur hato ketti yana bir bor urinib ko\'ring!')
          })
        }
    })
  }
  getSource(i, token){
      this.firebaseService.getDoc('/ouq/handbooks/rejected/'+token)
      .then(snapshot=>{
        if (snapshot.exists()) {
          this.genTable((snapshot.val()));
          this.source = snapshot.val();
        }else{
          this.tmp.loading = false;
          this.tmp.emptyPage = true;
          // alert('Ma\'lumot yo\'q!');
        }
      }).catch(error=>{
        if (i<10) {
          setTimeout(()=>{
            this.getSource((i+1), token)
          },1000)
        }else{
          alert('#632 error ' + error);
        }
      })
  }
  genTable(source){
      var products = []; 
      (Object.keys(source)).reverse().forEach(async(res, idx, sss)=>{
          Object.keys(source[res]).forEach(key=>{
            if ((key.indexOf('file') > 0) && source[res][key]) {
              var arr = '<div class="file_download">'
              source[res][key].forEach((file)=>{  
                arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              arr += '</div>'
              source[res][key] = arr;
            }
          })
          if(source[res]['rejected'] && source[res]['rejected'].length){
            const who = (source[res]['rejected'][(source[res]['rejected'].length)-1]).who;
            const message = (source[res]['rejected'][(source[res]['rejected'].length)-1]).message;
            source[res]['rejected'] = '<strong>bo\'lim: </strong>' + `${who==='PUBLISHINGDEP'?'Noshirlik':(who==='KAFEDRA'?'Fakultet uslubiy kengash raisi':(who==='ADMIN'?'Insitut uslubiy kengash ma\'suli':''))}` + ' </br>'
                                           +'<strong>xabar: </strong> ' + message
          }
          // if (source[res]['created']) {
          //   source[res]['created'] = this.datePipe.transform((new Date(source[res]['created'])), 'HH:mm dd-MM-yyyy');
          // }
          products.push(source[res]);
        if (sss.length === (idx+1)) {
          this.dataSource.load(products)
          this.tmp.loading = false;
        }
      })
  }
  test(){
    this.dataSource['data'].forEach((obj) => {
        obj.ishnmi = 'test test test'
    });
    this.dataSource.refresh();
  }
  public settings = {
    edit: {
      editButtonContent: 'EDIT ',
      saveButtonContent: 'SAVE ',
      cancelButtonContent: 'CANCEL ',
      confirmSave: true,
      // confirmDelete: true,
      // deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      // edit: true,
      delete: false,
      position: 'right'
    },
    columns: {
      ishnmu: {
        title: 'Ishlanma muallifi',
      },
      ishlt: {
        title: 'Ishlanma turi'
      },
      ishln: {
        title: 'Ishlanma nomi'
      },
      ishh: {
        title: 'Ishlanma hajmi(betlar va b.t)'
      },
      icht: {
        title: 'Ichki taqriz'
      },
      ichtfile: {
        title: 'Ichki taqriz faylari',
        type: 'html',
        editable: false
      },
      tasht: {
        title: 'Tashqi taqriz'
      },
      tashtfile: {
        title: 'Tashqi taqriz faylari',
        type: 'html',
        editable: false
      },
      rbrt: {
        title: 'Reja bo\'yicha yoki rejadan tashqari'
      },
      kybn: {
        title: 'Kafedra yig\'ilishi bayoni nomeri'
      },
      kybs: {
        title: 'Kafedra yig\'ilishi bayoni sanasi'
      },
      kybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      fybn: {
        title: 'Fakultet yig\'ilishi bayoni nomeri'
      },
      fybs: {
        title: 'Fakultet yig\'ilishi bayoni sanasi'
      },
      fybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      xau: {
        title: 'Xorijiy adabiyotlar ulushi(%da)'
      },
      uslfile: {
        title: 'faylar',
        type: 'html',
        editable: false
      },
      status: {
        title: 'Falyni yangilash',
        type: 'custom',
        renderComponent: UpdateFileRenComponent,
        editable: false
      },
      rejected: {
        title: 'Rad etilgan xabar',
        type: 'html',
        editable: false
      },
      resend: {
        title: 'Qayta jo\'natish',
        type: 'custom',
        renderComponent: ResendComponent,
        editable: false
      }
    },
  };
  goBackOnError(oldData) {
    this.dataSource['data'].forEach(res=>{
      if (res.added_id === oldData.added_id) {
        res = oldData;
      }
    })
    this.dataSource.refresh();
  }
  onSaveConfirm(event){
    Object.keys(this.source).forEach(key=>{
      if (this.source[key].added_id === event.newData.added_id) {
        var newData = event.newData
        Object.keys(newData).forEach(rowKey=>{
          if ((rowKey.indexOf('file') > 0)) newData[rowKey] = (this.source[key][rowKey])?this.source[key][rowKey]:'';
        })
        if (newData['rejected'] && newData['rejected'].length) newData['rejected'] = this.source[key].rejected;
        this.firebaseService.updateDoc(`/ouq/handbooks/rejected/${newData.token}/${newData.added_id}`, newData).then(()=>{
          this.source[key] = newData;
          console.log(this.source)
        }).catch(error=>{
          this.goBackOnError(event.data)
          alert('#493 Nimadur hato ketti yana bir bor urinib ko\'ring!' + error)
        })
      }
    })
    event.confirm.resolve();
  }
  // onFileUpdate(newData){
  //   this.source.forEach(res=>{
  //     if (res.added_id === newData.added_id) {
  //       newData.file = res.file;
  //     }
  //   })
  //   console.log(newData);

  // }
  
}

          // if (parseInt(data.key)<parseInt(last_id)) {
          //   arr[`${data.key}`] = data.val()
          // }else if(parseInt(data.key)===parseInt(last_id)){
          //   arr[`${data.key}`] = data.val()
          //   console.log('1', arr, Object.keys(arr));
          //   this.genTableSourceAll(arr, Object.keys(arr), 0);
          // }else{
          //   console.log(3)
          // }

  // genTableSourceAll(source, keys, queue){
  //   var arr = {};
  //   arr['indexId'] = (source[`${keys[queue]}`][(source[`${keys[queue]}`]).length-1].indexId).replace("ddd", ".");
  //   arr['addBy'] = ((source[`${keys[queue]}`][(source[`${keys[queue]}`]).length-1].indexId).user)
  //   source[`${keys[queue]}`].forEach((dat, idx)=>{
  //     if (dat.title!=="файл") {
  //      arr[`title${idx}`] = dat.value?dat.value:'';
  //     }else{
  //       arr[`title${idx}`] = '';  
  //       if (dat.value) {
  //         arr[`title${idx}`] = '<div class="file_download">';  
  //         dat.value.forEach((fet, indexFile)=>{
  //           arr[`title${idx}`] += `<a href="${fet.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`  
  //         })
  //         arr[`title${idx}`] += '</div>'
  //       }
  //     }
  //   })
  //   arr['source'] = (source[`${keys[queue]}`][(source[`${keys[queue]}`]).length-1].indexId)
  //   this.source.push(arr);
  //   this.genTableSetAll(source, keys, queue);
  // // source.forEach(res=>{
  // //   var arr = {};
  // //   res.forEach((dat, idx)=>{
  // //     arr[`title${idx}`] = dat.value;
  // //   })
  // //     this.source.push(arr);
  // //   this.tmp.tableLoading = false;
  // // })
  // }
  // genTableSetAll(source, keys, queue){
  //   var indexId = (source[`${keys[queue]}`][(source[`${keys[queue]}`]).length-1].indexId).replace("ddd", "d");
  //   this.indexFormsService.forms.subscribe(forms=>{
  //     forms.forEach(form=>{
  //       if (form.indexId === indexId) {
  //         console.log(2, indexId)
  //         this.settings.columns['indexId'] = {title: 'Индех', editable: false};
  //         form.indexForm.forEach((res, idx)=>{
  //           if (res.title!=="файл") {
  //             this.settings.columns[`title${idx}`] = {title: res.title};
  //           }else{
  //             this.settings.columns[`title${idx}`] = {title: res.title, type: 'html', editable: false};
  //           }
  //         })
  //         this.settings.columns['addBy'] = {title: "Қўшди", editable: false}
  //         this.settings.columns['comment'] = {title: "Шархлар"}
  //         this.table.push({settings: this.settings, source: this.source})
  //         console.log(3, this.settings)
  //         console.log(4, form)
  //         if (keys.length - 1 === queue) {
  //           this.tmp.tableLoading = false;
  //         }else{
  //            this.initializeVar();
  //            this.genTableSourceAll(source, keys, queue+1)
  //         }
  //         // this.indexTitles = {title0:{title: '1'},title1:{title:'2'}}
  //         // this.settings.columns = this.indexTitles;
  //         // this.genTableSource(source);
  //       }
  //     })
  //   })
  // }