import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HandbooksComponent } from './handbooks.component';


const routes: Routes = [
  {
    path: '',
    component: HandbooksComponent,
    data: {
        title: "Qo'llanmalar",
        breadcrumb: "Qo'llanmalar"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HandbooksRoutingModule { }
