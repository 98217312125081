import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddHandbookComponent } from './add-handbook.component';


const routes: Routes = [
  {
    path: '',
    component: AddHandbookComponent,
    data: {
      title: "Qo'llanma qo'shish",
      breadcrumb: "Qo'llanma qo'shish"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddHandbookRoutingModule { }
