import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../shared/service/data.service';
import { InjiService } from '../../shared/service/inji.service';
import { FirebaseService } from '../../shared/service/firebase.service';
import { UpdateFileRenComponent } from './update-file-ren.component';
import { RejectRenComponent } from './reject.component';
import { ConfirmAlertComponent } from './confirm.component';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { DatePipe } from '@angular/common';

import firebase from "firebase/app";
import "firebase/database";

interface tmp{
  loading: boolean;
  emptyPage: boolean;
}
@Component({
  selector: 'app-publishing-dep',
  templateUrl: './publishing-dep.component.html',
  styleUrls: ['./publishing-dep.component.scss'],
  providers: [DatePipe]
})

export class PublishingDepComponent implements OnInit {
  dataSource: LocalDataSource = new LocalDataSource();
  tmp: tmp = {loading: true, emptyPage: false};
  homeTypes;
  source = [];
  constructor(
    private dataService: DataService,
    private firebaseService: FirebaseService,
    private injiService: InjiService,
    private datePipe: DatePipe
  ) {
    // dataService.pvoKaf$.subscribe(res=>{
    //   this.products = res;
    //   this.tmp.loading = false;
    // })
    this.getSource(0)
  }
  ngOnInit() {
    this.injiService.updateRowSubjects.subscribe( dd => {
      switch (dd['action']) {
        case "updateFile":
    console.log(7)
          this.onFileUpdate(dd);
          break;
        case "rejectedRow":
          this.rejectedRow(dd['data'], dd['rejectedMessage']);
          break;
        case "confirm":
          this.confirmRow(dd['data']);
          break;        
        default:
          // code...
          break;
      }
    });
  }
  confirmRow(rowData){
     Object.keys(this.source).forEach(key=>{
       if (this.source[key].added_id === rowData.added_id) {
         this.firebaseService.updateDoc(`ouq/handbooks/beingchecked/${rowData.token}/${rowData.added_id}`, {status: 'PUBLISHINGDEP'}).then(()=>{
            this.dataSource['data'].forEach((row, index)=>{
              if(row.added_id===rowData.added_id){
                delete this.dataSource['data'][index];
              }
            });
            this.dataSource.refresh();
         }).catch(error=>{
            alert('#539 Nimadur hato ketti yana bir bor urinib ko\'ring!')
         })
       }
     })
  }
  rejectedRow(rowData, rejectedMessage){  
      var sourceKey;
      Object.keys(this.source).forEach(key=>{
        if (this.source[key].added_id === rowData.added_id) {
            sourceKey = key;
            Object.keys(rowData).forEach(rowKey=>{
              if ((rowKey.indexOf('file') > 0)){
                rowData[rowKey] = this.source[key][rowKey];
              } 
            })
            if (!this.source[key]['rejected']) rowData['rejected'] = [];
            rowData['rejected'].push(rejectedMessage);
        }
      })
      this.updateTable(rowData, sourceKey, 'beingchecked', 'rejected')
  }
  updateTable(rowData, sourceKey, from, to){
      console.log('rowData', rowData)
      this.firebaseService.setDoc(`ouq/handbooks/${to}/${rowData.token}/${rowData.added_id}`, rowData).then(()=>{
        this.firebaseService.removeDoc(`ouq/handbooks/${from}/${rowData.token}/${rowData.added_id}`).then(()=>{
          this.dataSource['data'].forEach((row, index)=>{
            if(row.added_id===rowData.added_id){
              delete this.dataSource['data'][index];
            }
          });
          delete this.source[sourceKey];
          this.dataSource.refresh();
        }).catch(error=>{
          alert('#535 Nimadur hato ketti yana bir bor urinib ko\'ring!')
        })
      }).catch(error=>{
        alert('#534 Nimadur hato ketti yana bir bor urinib ko\'ring!')
      });
  }
  deleteOldFiles(files, i, index, toNewSource){
    // console.log('files ', files)
    const storageRef = firebase.storage().ref();
    if (files) {
      files.forEach(file=>{
        const desertRef = storageRef.child('ouq/'+ 'handbooks' + '/' + file.name)
        desertRef.delete().then(() => {
          // this.source[index].file = [];
            // this.source[index].uslfile = filesUrl;
          this.source[index].uslfile = toNewSource
          console.log('File deleted successfully newData')
        }).catch((error) => {
          console.log('Uh-oh, an error occurred!')
          if (i<10) {
            setTimeout(()=>{
              this.deleteOldFiles(files, (i+1), index, toNewSource);
            }, 2000)
          }else{
            this.dataSource.refresh();
          }
        })
      })
    }

  }
  onFileUpdate(dd) {
    console.log(8, dd)
    Object.keys(this.source).forEach((index)=>{
        if (this.source[index].added_id === dd['rowData'].added_id) {
          this.firebaseService.updateDoc(`/ouq/handbooks/beingchecked/${dd['rowData'].token}/${dd['rowData'].added_id}`, {uslfile: dd['filesUrl']}).then(async()=>{
            this.deleteOldFiles(this.source[index].uslfile, 0, index, dd['filesUrl']);
            await this.dataSource['data'].forEach(bbb=>{
              if (bbb.added_id === dd['rowData'].added_id) {
                 if (dd['filesUrl']) {
                    var row = dd['rowData'];
                    var arr = '<div class="file_download">'
                    dd['filesUrl'].forEach((file, idxFile, sssFile)=>{  
                      arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
                    })
                    arr += '</div>'
                    row.uslfile = arr;
                    bbb = row;
                 }
              }
            })
            this.dataSource.refresh();
          }).catch(error=>{
            alert('#532 Nimadur hato ketti yana bir bor urinib ko\'ring!')
          })
        }
    })
  }
  getSource(i){
      this.firebaseService.getDoc('/ouq/handbooks/beingchecked/')
      .then(snapshot=>{
        if (snapshot.exists()) {
          var arr = [];
          var arr2 = [];
          Object.keys(snapshot.val()).forEach((res, idx, sss)=>{
            Object.assign(arr, (snapshot.val())[res]);
            Object.assign(arr2, (snapshot.val())[res]);
          })
          this.genTable(arr);
          this.source = arr2;
        }else{
          this.tmp.loading = false;
          this.tmp.emptyPage = true;
          // alert('Ma\'lumot yo\'q!');
        }
      }).catch(error=>{
        if (i<10) {
          setTimeout(()=>{
            this.getSource(i+1)
          },1000)
        }else{
          alert('#632 error ' + error);
        }
      })
  }
  genTable(source){
      var products = []; 
      (Object.keys(source)).reverse().forEach(async(res, idx, sss)=>{
        if (source[res]['status'] === 'PVO') {
          Object.keys(source[res]).forEach(key=>{
            if ((key.indexOf('file') > 0) && source[res][key]) {
              var arr = '<div class="file_download">'
              source[res][key].forEach((file)=>{  
                arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              arr += '</div>'
              source[res][key] = arr;
            }
          })
          if(source[res]['rejected'] && source[res]['rejected'].length){
            const who = (source[res]['rejected'][(source[res]['rejected'].length)-1]).who;
            const message = (source[res]['rejected'][(source[res]['rejected'].length)-1]).message;
            source[res]['oldrej'] = '<strong>bo\'lim: </strong>' + `${who==='PUBLISHINGDEP'?'Noshirlik':(who==='KAFEDRA'?'Fakultet uslubiy kengash raisi':(who==='ADMIN'?'Insitut uslubiy kengash ma\'suli':''))}` + ' </br>'
                                           +'<strong>xabar: </strong> ' + message
          }
          // if (source[res]['created']) {
          //   source[res]['created'] = this.datePipe.transform((new Date(source[res]['created'])), 'HH:mm dd-MM-yyyy');
          // }
          products.push(source[res]);
        }
        if (sss.length === (idx+1)) {
          this.dataSource.load(products)
          this.tmp.loading = false;
        }
      })
  }
  test(){
    this.dataSource['data'].forEach((obj) => {
        obj.ishnmi = 'test test test'
    });
    this.dataSource.refresh();
  }
  public settings = {
    edit: {
      editButtonContent: 'EDIT ',
      saveButtonContent: 'SAVE ',
      cancelButtonContent: 'CANCEL ',
      confirmSave: true,
      // confirmDelete: true,
      // deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      // edit: true,
      delete: false,
      position: 'right'
    },
    columns: {
      ishnmu: {
        title: 'Ishlanma muallifi',
      },
      ishlt: {
        title: 'Ishlanma turi'
      },
      ishln: {
        title: 'Ishlanma nomi'
      },
      ishh: {
        title: 'Ishlanma hajmi(betlar va b.t)'
      },
      icht: {
        title: 'Ichki taqriz'
      },
      ichtfile: {
        title: 'Ichki taqriz faylari',
        type: 'html',
        editable: false
      },
      tasht: {
        title: 'Tashqi taqriz'
      },
      tashtfile: {
        title: 'Tashqi taqriz faylari',
        type: 'html',
        editable: false
      },
      rbrt: {
        title: 'Reja bo\'yicha yoki rejadan tashqari'
      },
      kybn: {
        title: 'Kafedra yig\'ilishi bayoni nomeri'
      },
      kybs: {
        title: 'Kafedra yig\'ilishi bayoni sanasi'
      },
      kybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      fybn: {
        title: 'Fakultet yig\'ilishi bayoni nomeri'
      },
      fybs: {
        title: 'Fakultet yig\'ilishi bayoni sanasi'
      },
      fybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      xau: {
        title: 'Xorijiy adabiyotlar ulushi(%da)'
      },
      uslfile: {
        title: 'faylar',
        type: 'html',
        editable: false
      },
      status: {
        title: 'Falyni yangilash',
        type: 'custom',
        renderComponent: UpdateFileRenComponent,
        editable: false
      },
      reject: {
        title: 'Rad etish',
        type: 'custom',
        renderComponent: RejectRenComponent,
        editable: false
      },
      oldrej:{
        title: 'Oldin qaytarilgan',
        type: 'html',
        editable: false
      },
      nextStep: {
        title: 'Tasdiqlash',
        type: 'custom',
        renderComponent: ConfirmAlertComponent,
        editable: false
      }

    },
  };
  goBackOnError(oldData) {
    this.dataSource['data'].forEach(res=>{
      if (res.added_id === oldData.added_id) res = oldData;
    })
    this.dataSource.refresh();
  }
  onSaveConfirm(event){
    Object.keys(this.source).forEach(key=>{
      if (this.source[key].added_id === event.newData.added_id) {
        var newData = event.newData
        Object.keys(newData).forEach(rowKey=>{
          if ((rowKey.indexOf('file') > 0)) newData[rowKey] = (this.source[key][rowKey])?this.source[key][rowKey]:'';
        })
        if (newData['rejected'] && newData['rejected'].length) newData['rejected'] = this.source[key].rejected;
        this.firebaseService.updateDoc(`/ouq/handbooks/beingchecked/${newData.token}/${newData.added_id}`, newData).then(()=>{
          this.source[key] = newData;
        }).catch(error=>{
          this.goBackOnError(event.data)
          alert('#493 Nimadur hato ketti yana bir bor urinib ko\'ring!' + error)
        })
      }
    })
    event.confirm.resolve();
  }
  // onFileUpdate(newData){
  //   this.source.forEach(res=>{
  //     if (res.added_id === newData.added_id) {
  //       newData.file = res.file;
  //     }
  //   })
  //   console.log(newData);

  // }
}
