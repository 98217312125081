import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RejectedFieldComponent } from './rejected-field.component';


const routes: Routes = [
  {
    path: '',
    component: RejectedFieldComponent,
    data: {
        title: "Rad etilgan",
        breadcrumb: "Rad etilgan"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RejectedFieldRoutingModule { }
