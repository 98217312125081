import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RejectedFieldRoutingModule } from './rejected-field-routing.module';
import { RejectedFieldComponent } from './rejected-field.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SmartTableModule } from 'ng2-smart-table';
// import { RenderComponent } from '../publishing-dep/render.component';
// import { RejectRenComponent } from '../publishing-dep/reject.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    RejectedFieldComponent,
    // RenderComponent,
    // RejectRenComponent
  ],
  imports: [
    RejectedFieldRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
  ],
  providers: [NgbActiveModal],
  bootstrap: [
  ],
  entryComponents: [
  ]
})
export class RejectedFieldModule { }
