import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
import { IndexFormsService } from './index-forms.service'; 
import { DataService } from './data.service'; 
import { AuthService } from './auth.service'; 
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	indexId?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(
		@Inject(WINDOW) private window,
		private indexFormsService: IndexFormsService,
		private dataService: DataService,
		private authService: AuthService
	) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		this.authService.user.subscribe(user=>{
			if (user) {
				switch (user.ouqRole) {
					case "ADMIN":
						this.getMenuItemsAdmin();
						break;
					case "PVO":
						this.getMenuItemsPvo();
						// code...
						break;
					case "PUBLISHINGDEP":
						this.getMenuItemsPubD();
						// code...
						break;
					case "KAFEDRA":
						this.getMenuItemsKaf();
						// code...
						break;
					default:
						// code...
						break;
				}
			}
		})
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	getMenuItemsPvo(){
		this.MENUITEMS = [
			{
				title: 'Qo\'llanma qo\'shish', path: '/add-handbook', icon: 'plus', type: 'link', active: false
			},
			{
				title: 'Rad etilgan', path: '/rejected-field', icon: 'thumbs-down', type: 'link', active: false
			},
			{
				title: 'Ariza holati', path: '/being-checked', icon: 'check-circle', type: 'link', active: false
			},
			{
				title: 'Mening qo\'llanmalarim', path: '/my-handbooks', icon: 'file-text', type: 'link', active: false
			},
		]
		this.items.next(this.MENUITEMS);
	}
	getMenuItemsPubD(){
		this.MENUITEMS = [
			{
				title: 'Noshirlik', path: '/publishing-dep', icon: 'check', type: 'link', active: false
			}
		]
		this.items.next(this.MENUITEMS);
	}
	getMenuItemsKaf(){
		this.MENUITEMS = [
			{
				title: 'Fakultet', path: '/fakultet', icon: 'check', type: 'link', active: false
			}
		]
		this.items.next(this.MENUITEMS);
	}
	getMenuItemsAdmin(){
		this.MENUITEMS = [
			{
				title: 'Qo\'llanmalar', path: '/handbooks', icon: 'file-text', type: 'link', active: false
			},
			{
				title: 'Tekshirish', path: '/check-ah', icon: 'check-square', type: 'link', active: false
			},
			{
				title: 'Foydalanuvchilar', icon: 'user-plus', type: 'sub', active: false, children: [
					{ path: '/users/list-user', title: 'ro\'yxati', type: 'link' },
					// { path: '/users/create-user', title: 'yaratish', type: 'link' },
					{ path: '/users/register-new', title: 'Yangi foydalanuvchi', type: 'link' },
				]
			}
		]
		this.items.next(this.MENUITEMS);
	}

	MENUITEMS: Menu[] = []
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
function replaceAll(str, from, to){
    var n = '';
    if (str) {
	    for(let g of str){
	      if (g === from) {
	        g = to;  
	      }
	      n += g;
	    }
    }
    return n;
}