export const environment = {
  production: true,
  firebaseConfig:{
    apiKey: "AIzaSyBmzKSP6a7bHTJWRZTqRgF3u8ucSJB2_fg",
    authDomain: "pvorey.firebaseapp.com",
    databaseURL: "https://pvorey-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pvorey",
    storageBucket: "pvorey.appspot.com",
    messagingSenderId: "975847085157",
    appId: "1:975847085157:web:5008868ba373c480ffa28c",
    measurementId: "G-GRE7FMXH99"
  }
  // firebaseConfig:{ //test
  //   apiKey: "AIzaSyCBAtL3VWdvhXSn8ttioJ8Z4_9sXzNQ_vY",
  //   authDomain: "test-ea80b.firebaseapp.com",
  //   projectId: "test-ea80b",
  //   storageBucket: "test-ea80b.appspot.com",
  //   messagingSenderId: "406076438341",
  //   appId: "1:406076438341:web:b1a4474612da03a71eb02c",
  //   measurementId: "G-NTH1QWT76J"
  // }
};
