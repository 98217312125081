import {Component, Output, EventEmitter, OnInit } from '@angular/core';
import { InjiService } from '../../shared/service/inji.service';
import firebase from "firebase/app";
import "firebase/database";
import { SimpleModalService } from 'ngx-simple-modal';
import { ConfirmComponent } from '../../shared/components/alert/confirm/confirm.component';

@Component({
    selector: 'reject-component',
    template: `
	    <div class="d-flex flex-nowrap text-white">
	      <button type="button" class="btn btn-info" (click)="showConfirm()">
	        <i class="fa fa-check"></i>
	      </button>
	    </div>
    `,
    styles: [`.d-flex{justify-content:center;}`]
})
export class ConfirmAlertComponent implements OnInit  {

    rowData: any;
    // confirmResult = null;

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(
    	private InjiService: InjiService,
	    private SimpleModalService: SimpleModalService
    	){
    }
    ngOnInit(){
    }
    resend(){
        console.log('confirm')

    }
    showConfirm() {
        this.SimpleModalService.addModal(ConfirmComponent, {
          title: 'Tasdiqlash',
          message: 'Ma\'lumotlar to\'g\'rimi?'})
          .subscribe((isConfirmed) => {
            if (isConfirmed) {
            	this.InjiService.updateRowSubjects.next({action:'confirm', data:this.rowData})
            }
        });
    }
}
