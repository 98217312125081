import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../shared/service/data.service';
import { AuthService } from '../../shared/service/auth.service';
import { FirebaseService } from '../../shared/service/firebase.service';
import { RejectRenComponent } from '../publishing-dep/reject.component';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { DatePipe } from '@angular/common';

import firebase from "firebase/app";
import "firebase/database";

import { ExcelService } from '../../shared/service/excel.service';
import * as XLSX from 'xlsx';

interface tmp{
  loading: boolean;
  emptyPage: boolean;
}
@Component({
  selector: 'app-handbooks',
  templateUrl: './handbooks.component.html',
  styleUrls: ['./handbooks.component.scss'],
  providers: [DatePipe]
})
export class HandbooksComponent {
  dataSource: LocalDataSource = new LocalDataSource();
  tmp: tmp = {loading: true, emptyPage: false};
  homeTypes;
  source = [];
  user;
  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private datePipe: DatePipe

  ) {
    authService.user.subscribe(user=>{
      this.user = user;
      this.getSource(0)
    })
  }
  ngOnInit() {
  }
  fileName = 'ExcelSheet.xlsx';
  exportexcel(): void {
     /* table id is passed over here */   
      this.tmp.loading = true;
      this.settings.pager.display = false;
      setTimeout(()=>{
        this.tmp.loading = false;
        setTimeout(()=>{

            // this.excelService.exportAsExcelFile(document.getElementById('excelTable').children[0]);
             const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(document.getElementById('excelTable').children[0]);

             const wb: XLSX.WorkBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

             XLSX.writeFile(wb, this.fileName);
        }, 1000)
      }, 1000)
    
  }
  getSource(i){
      var path;
      switch (this.user.ouqRole) {
        case "ADMIN":
          // code...
            this.firebaseService.getDoc('/ouq/handbooks/checked')
            .then(snapshot=>{
              if (snapshot.exists()) {
                var arr = [];
                var arr2 = [];
                Object.keys(snapshot.val()).forEach((res, idx, sss)=>{
                  Object.assign(arr, (snapshot.val())[res]);
                  Object.assign(arr2, (snapshot.val())[res]);
                })
                this.genTable(arr);
                this.source = arr2;
              }else{
                this.tmp.loading = false;
                this.tmp.emptyPage = true;
              }
            }).catch(error=>{
              if (i<10) {
                setTimeout(()=>{
                  this.getSource(i+1)
                },1000)
              }else{
                alert('#632 error ' + error);
              }
            })
          break;
        case "PVO":
            this.firebaseService.getDoc('/ouq/handbooks/checked/'+this.user.id)
            .then(snapshot=>{
              if (snapshot.exists()) {
                this.genTable((snapshot.val()));
                this.source = snapshot.val();
              }else{
                this.tmp.loading = false;
                this.tmp.emptyPage = true;
              }
            }).catch(error=>{
              if (i<10) {
                setTimeout(()=>{
                  this.getSource(i+1)
                },1000)
              }else{
                alert('#632 error ' + error);
              }
            })
          break;
        default:
          // code...
          break;
      }
  }
  genTable(source){
      var products = []; 
      (Object.keys(source)).reverse().forEach(async(res, idx, sss)=>{
          Object.keys(source[res]).forEach(key=>{
            if ((key.indexOf('file') > 0) && source[res][key]) {
              var arr = '<div class="file_download">'
              source[res][key].forEach((file)=>{  
                arr += `<a href="${file.url}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              arr += '</div>'
              source[res][key] = arr;
            }
          })
          if(source[res]['rejected'] && source[res]['rejected'].length){
            const who = (source[res]['rejected'][(source[res]['rejected'].length)-1]).who;

            const message = (source[res]['rejected'][(source[res]['rejected'].length)-1]).message;
            source[res]['rejected'] = '<strong>bo\'lim: </strong>' + `${who==='PUBLISHINGDEP'?'Noshirlik':(who==='KAFEDRA'?'Fakultet uslubiy kengash raisi':(who==='ADMIN'?'Insitut uslubiy kengash ma\'suli':''))}` + ' </br>'
                                           +'<strong>xabar: </strong> ' + message
          }
          // if (source[res]['created']) {
          //   source[res]['created'] = this.datePipe.transform((new Date(source[res]['created'])), 'HH:mm dd-MM-yyyy');
          // }
          products.push(source[res]);
        if (sss.length === (idx+1)) {
          this.dataSource.load(products)
          this.tmp.loading = false;
        }
      })
  }
  public settings = {
    // edit: {
    //   editButtonContent: 'EDIT ',
    //   saveButtonContent: 'SAVE ',
    //   cancelButtonContent: 'CANCEL ',
    //   confirmSave: true,
    // },
    pager: {
      display: true
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'none'
    },
    columns: {
      ishnmu: {
        title: 'Ishlanma muallifi',
      },
      ishlt: {
        title: 'Ishlanma turi'
      },
      ishln: {
        title: 'Ishlanma nomi'
      },
      ishh: {
        title: 'Ishlanma hajmi(betlar va b.t)'
      },
      icht: {
        title: 'Ichki taqriz'
      },
      ichtfile: {
        title: 'Ichki taqriz faylari',
        type: 'html',
        editable: false
      },
      tasht: {
        title: 'Tashqi taqriz'
      },
      tashtfile: {
        title: 'Tashqi taqriz faylari',
        type: 'html',
        editable: false
      },
      rbrt: {
        title: 'Reja bo\'yicha yoki rejadan tashqari'
      },
      kybn: {
        title: 'Kafedra yig\'ilishi bayoni nomeri'
      },
      kybs: {
        title: 'Kafedra yig\'ilishi bayoni sanasi'
      },
      kybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      fybn: {
        title: 'Fakultet yig\'ilishi bayoni nomeri'
      },
      fybs: {
        title: 'Fakultet yig\'ilishi bayoni sanasi'
      },
      fybfile: {
        title: 'Kafedra yig\'ilishi bayoni faylari',
        type: 'html',
        editable: false
      },
      xau: {
        title: 'Xorijiy adabiyotlar ulushi(%da)'
      },
      uslfile: {
        title: 'faylar',
        type: 'html',
        editable: false
      }
      // created: {
      //   title: 'Yuklangan sanasi'
      // }
    },
  };
  goBackOnError(oldData) {
    this.dataSource['data'].forEach(res=>{
      if (res.added_id === oldData.added_id) {
        res = oldData;
      }
    })
    this.dataSource.refresh();
  }
  onSaveConfirm(event){
    Object.keys(this.source).forEach(key=>{
      if (this.source[key].added_id === event.newData.added_id) {
        var newData = event.newData
        newData.file = this.source[key].file;
        if (newData['rejected'] && newData['rejected'].length) {
          newData['rejected'] = this.source[key].rejected;
        }
        this.firebaseService.updateDoc(`/ouq/handbooks/rejected/${newData.token}/${newData.added_id}`, newData).then(()=>{
          this.source[key] = newData;
          console.log(this.source)
        }).catch(error=>{
          this.goBackOnError(event.data)
          alert('#493 Nimadur hato ketti yana bir bor urinib ko\'ring!' + error)
        })
      }
    })
    event.confirm.resolve();
  }
}
