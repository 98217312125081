import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../shared/service/data.service';
import { FirebaseService } from '../../../shared/service/firebase.service';
import { MultiSelComponent } from './multi-sel.component';

interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  public user_list = []
  tmp: tmp = {loading: true};
  constructor(
    private dataService: DataService,
    private firebaseService: FirebaseService
  ) {
    // dataService.pvoKaf$.subscribe(async(res)=>{
    //   this.user_list = await res;
    //   this.tmp.loading = false;
    // })
    this.getUsers();
  }
  getUsers(){
      this.firebaseService.getColl("users")
      .then(async snapshot => {
        await snapshot.forEach((doc) => {
          var data = doc.data();
          data['id'] = doc.id;
          switch ((data)['ouqRole']) {
            case "PVO":
              (data)['ouqRole'] = 'O\'qituvchi';
              break;
            case "PUBLISHINGDEP":
              (data)['ouqRole'] = 'Noshirlik';
              break;
            case "KAFEDRA":
              (data)['ouqRole'] = 'Fakultet kengash raisi';
              break;  
            case "ADMIN":
              (data)['ouqRole'] = 'Admin';
              break;            
            default:
              
              break;
          }
          this.user_list.push(data)
        });
        this.tmp.loading = false;
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
  }
  public settings = {
    // delete: {
    //   confirmDelete: true,
    //   deleteButtonContent: '<a class="table_del_default_icon"></a>'
    // },
    edit: {
      editButtonContent: 'EDIT ',
      saveButtonContent: 'SAVE ',
      cancelButtonContent: 'CANCEL ',
      confirmSave: true,
    },
    actions: {
      add: false,
      edit: true,
      delete: false,
      position: 'right'
    },
    columns: {
      first_name: {
        title: 'Ismi',
      },
      last_name: {
        title: 'Familiyasi'
      },
      email: {
        title: 'Email'
      },
      ouqRole: {
        title: 'Vazifasi',
        editor: {
          type: 'list',
          config: {
            selectText: 'Select',
            list: [
              {value: 'O\'qituvchi', title:'O\'qituvchi'},
              {value: 'Noshirlik', title:'Noshirlik'},
              {value: 'Fakultet kengash raisi', title:'Fakultet kengash raisi'}
            ],
          },
        }
      }
    },
  };

  ngOnInit() {
  }
  async onSaveConfirm(event){
    var data = event.newData;
    switch ((data)['ouqRole']) {
      case "O\'qituvchi":
        (data)['ouqRole'] = 'PVO';
        break;
      case "Noshirlik":
        (data)['ouqRole'] = 'PUBLISHINGDEP';
        break;
      case "Fakultet kengash raisi":
        (data)['ouqRole'] = 'KAFEDRA';
        break;  
      case "Admin":
        (data)['ouqRole'] = 'ADMIN';
        break;            
      default:
        
        break;
    }
    console.log(data)
    await this.firebaseService.updateStore('users', data.id, {ouqRole: data.ouqRole})
    .then(() => {
        event.confirm.resolve();
    })
    .catch((error) => {
        console.error("Error updating document: ", error);
    });
  }
}

