import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
import { DataService } from "../../service/data.service";

@Component({
  selector: "app-inputautocomplete",
  template: `
	        <div class="form-group row" [formGroup]="group" *ngIf="!loading">
		        <label for="validationCustom{{random}}" class="col-xl-3 col-md-4">
		        	<span *ngFor="let validation of field.validations;">
		        		<span *ngIf="validation.name === 'required'">*</span>
		        	</span>
		        	{{field.label}}
		        </label>
		        <div class="col-md-8">
					  <ng-autocomplete
					    [data]="autoField"
					    class="form-control-inner"
					    [searchKeyword]="keyword"
					    (selected)='selectEvent($event)'
					    (inputChanged)='onChangeSearch($event)'
					    (inputFocused)='onFocused($event)'
					    (inputCleared)='inputCleared($event)'
					    [itemTemplate]="itemTemplate"
					    [notFoundTemplate]="notFoundTemplate"
					    id="ng-autocomplete_1"
					    >
					  </ng-autocomplete>
					  <ng-template #itemTemplate let-item>
					    <a [innerHTML]="item.name"></a>
					  </ng-template>

					  <ng-template #notFoundTemplate let-notFound>
				       	<div class="red">
						    topilmadi! 
						 </div>
					  </ng-template>

  					<ng-container *ngFor="let validation of field.validations;">
  				       	<div style="color:red;" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">
                    <div *ngIf="validation.name === 'required'">Ro'yxatdan tanlang!</div>
  					    </div>
  					</ng-container>
		        </div>
		    </div>
		`,
  styles: []
})
export class InputAutoCompleteComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  random: number;
  loading: boolean = true;
  autoField;
  constructor(private dataService: DataService) {
  }
  ngOnInit() {this.random = Math.floor(Math.random() * 100);
    if (this.field.pvoNames) {
      this.dataService.pvoKaf$.subscribe((pvo) =>{
        this.autoField = pvo.map(res=> ({name: res.fname + ' ' + res.lname + ' ' + (res.patronymic?res.patronymic:'')}));
        this.loading = false;
      })
    }else{
      this.loading = false;
    }
  }
  keyword = 'name';
  selectEvent(item) {
    this.group.controls[this.field.name].setValue(item.name);
    // this.tmp['name'] = 'selected';
    // ((<FormArray>this.indexForm.controls['formData']).controls[i]).setValue(item.name);
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.border = "none";
  }
  onChangeSearch(search: string) {
    this.group.controls[this.field.name].setValue('');
    // this.tmp['name'] = 'focused';
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.border = "1px solid red";
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.borderRadius = "4px";
  }
  onFocused(e) {
    // this.tmp['name'] = 'focused';
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.border = "1px solid red";
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.borderRadius = "4px";
  }
  inputCleared(e){
    // ((<FormArray>this.indexForm.controls['formData']).controls[i]).setValue(null);
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.border = "1px solid red";
    // (<HTMLElement>document.getElementById('ng-autocomplete_1').children[i]).style.borderRadius = "4px";
    // this.tmp['name'] = 'focused';
  }

}
