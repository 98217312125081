import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { ProductsModule } from './components/products/products.module';
import { UsersModule } from './components/users/users.module';
import { SettingModule } from './components/setting/setting.module';;
import { AuthModule } from './components/auth/auth.module';
import { AddHandbookModule } from './components/add-handbook/add-handbook.module';
import { BeingCheckedModule } from './components/being-checked/being-checked.module';
import { PublishingDepModule } from './components/publishing-dep/publishing-dep.module';
import { RejectedFieldModule } from './components/rejected-field/rejected-field.module';
import { KafedraModule } from './components/kafedra/kafedra.module';
import { CheckAhModule } from './components/check-ah/check-ah.module';
import { HandbooksModule } from './components/handbooks/handbooks.module';

import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestore } from '@angular/fire/firestore';
import { SimpleModalModule } from 'ngx-simple-modal';

import { environment } from './../environments/environment.prod';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
// import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    AuthModule,
    SharedModule,
    ProductsModule,
    UsersModule,
    AddHandbookModule,
    BeingCheckedModule,
    PublishingDepModule,
    RejectedFieldModule,
    KafedraModule,
    CheckAhModule,
    HandbooksModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SimpleModalModule.forRoot({container: "modal-container"})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(){
    firebase.initializeApp(environment.firebaseConfig);
    firebase.database();
    firebase.storage();
  }
}
