import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from '@angular/router';

import { AuthService } from '../../../shared/service/auth.service';

@Component({
  selector: 'app-register-pvo',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  
  @ViewChild('tabSet', {static: false}) tabSet;
  public registerForm: FormGroup;

  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.createRegisterForm();
  }



  async register() {
    this.loading = true;

    this.auth.signUp(this.registerForm.value).then(async res => {
        this.loading = false;
        this.registerForm.reset();
        alert("Foydalanuvchi muvaffaqiyatli qo'shildi!")

    }, async err => {
        this.loading = false;
        alert(err.message);
    });
  }


  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      ouqRole: ['', Validators.required]
    });
  }


  ngOnInit() {
  }

  onSubmit() {
    
  }

}
