import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KafedraComponent } from './kafedra.component';


const routes: Routes = [
  {
    path: '',
    component: KafedraComponent,
    data: {
      title: "Ariza holati",
      breadcrumb: "Ariza holati"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KafedraRoutingModule { }
