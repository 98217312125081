import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AddHandbookRoutingModule } from './add-handbook-routing.module';
import { AddHandbookComponent } from './add-handbook.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutocompleteModule } from 'ng2-input-autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AddHandbookComponent,
  ],
  imports: [
    AddHandbookRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbModule,
    NgxDropzoneModule,
    AutocompleteModule.forRoot(),
    NgCircleProgressModule.forRoot({}),
  ],
  providers: [NgbActiveModal],
  bootstrap: [
  ],
  entryComponents: [
  ]
})
export class AddHandbookModule { }
