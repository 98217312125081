import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldConfig, FieldData, PvoKafInter, Chart } from '../interface/field.interface'

interface RatingInt{
  chartFakul?: Chart;
  chartKaf?: Chart;
  kafedraSet?: any;
  kafedra?: any;
  fakultetSet?: any;
  fakultet?: any;
  settings?: any;
  source?: any;
}
@Injectable({
  providedIn: 'root'
})
export class DataService {

  field: FieldData[] = [];
  field$ = new BehaviorSubject<FieldData[]>(this.field);
  
  checkFieldLength$ = new BehaviorSubject<number>(0)
  fieldsRejected$ = new BehaviorSubject<number>(0);
  // pvoKaf: PvoKafInter[] = [];
  pvoKaf$ = new BehaviorSubject<PvoKafInter[]>([]);
  _tizim = [
    {
      fakultet: "Mexanika - mashinasozlik",
      kafedralar: [
         "Mashinasozlik texnologiyasi va avtomatlashtirish",
         "Oliy matematika",
         "Tadbiqiy mexanika", 
         "Texnologik mashinalar va jihozlar",
         "Yer usti transport tizimlari va ularni ekspluatatsiyasi",
      ]
    },
    {
      fakultet: "Energetika",
      kafedralar: [
        "Elektr energetika",
        "Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari",
        "Fizika",
      ]
    },
    {
      fakultet: "Kimyo texnologiya",
      kafedralar: [
        "Hayot faoliyati xavfsizligi",
        "Jismoniy tarbiya",
        "Kimyoviy texnologiya",
        "Oziq-ovqatlar texnologiyasi",
        "Qishloq xo'jalik mahsulotlarini saqlash va ularni qayta ishlash texnologiya",
        "Umumiy kimyo",
      ]
    },
    {
      fakultet: "Qurilish",
      kafedralar: [
        "Bino va inshootlar qurilishi",
        "Muhandislik kommunikatsiyalari qurilishi va montaji",
        "O'zbekiston tarixi va Ijtimoiy fanlar",
        "Geodeziya, kartografiya va kadastr",
      ]
    },
    {
      fakultet: "Ishlab chiqarishda boshqaruv",
      kafedralar: [
        "Iqtisodiyot",
        "Menejment",
        "Tillarni o'rgatish",
        "Buxgalteriya xisobi va audit",
      ]
    },
    {
      fakultet: "Yengil sanoat va to'qimachilik",
      kafedralar: [
        "Yengil sanoat texnologiyalari va jixozlari",
        "O'zbek tili va adabiyoti",
        "Tabiiy tolalar",
      ]
    },
    {
      fakultet: "Arxitektura va qurilish materiallari",
      kafedralar: [
        "Arxitektura",
        "Qurilish materiallari buyumlari va konstruksiyalari ishlab chiqish",
        "Chizma geometriya va muhandislik grafikasi",
      ]
    },
    {
      fakultet: "Kompyuterlashgan loyihalash tizimlari",
      kafedralar: [
        "Elektronika va asbobsozlik",
        "Intellektual muhandislik tizimlari",
        "Metrologiya standartlashtirish va maxsulot sifati menejmenti",
      ]
    }  
  ]
  rating$ = new BehaviorSubject<RatingInt>(null);
  get tizim(){
    return this._tizim;
  }

  constructor() { }
}
//   INDEXTITLES: indexTitlesInt[] = [
//   	{	
//   		title: "Фарғона политехника институтида дунёнинг нуфузли 1000 талигига кирувчи олий таълим муассасаларида PhD (фалсафа доктори ёки фан номзоди) ёки DSc (фан доктори) каби илмий даражаларни олган профессор-ўқитувчилар улуши ҳақидаги",
//   		code: "1.1",
//   	  link: "<a href='/pvorey/indexes/1d1'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтининг  рейтингни аниқлаш йилида дунёнинг нуфузли 1000 талигига кирувчи олий таълим муассасаларида ўқув машғулотлар (маърузалар, амалий машғулотлар, семинар-тренинглар) ўтказган профессор-ўқитувчилари ҳақидаги",
// 		  code: "1.2"  	,
//       link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//   	},
//   	{
//   		title: "Фарғона политехника институтининг фан доктори (DSc-фан доктори) илмий даражасига эга (шунингдек, илмий даражага эга бўлмай профессор илмий унвонини олган ёки унга тенглаштирилган) профессор-ўқитувчилари ҳақида",
//   		code: "1.3.1",
//   	  link: "<a href='/pvorey/indexes/1d3d1'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтининг фан номзоди (PhD) илмий даражасига эга (шунингдек, илмий даражага эга бўлмай доцент илмий унвонини олган) профессор-ўқитувчилари улуши ҳақида",
//   		code: "1.3.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг докторлик диссертациясини ҳимоя қилиш самарадорлиги ҳақидаги",
//   		code: "1.4",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг ҳалқаро кўрсаткичларга кўра профессор-ўқитувчиларнинг илмий мақолаларига («Web of Science», «Scopus», «Google Scholar» ёки бошқа халқаро эътироф этилган базаларда мавжуд бўлган журналлар бўйича) иқтибослар ҳақида",
//   		code: "1.5",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтининг рейтингни аниқлаш йилида халқаро илмий журналларда («Web of Science», «Scopus» ва бошқа халқаро эътироф этилган базаларга киритилган журналларда) чоп этилган илмий мақолалари ҳақида",
//   		code: "1.6.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг рейтингни аниқлаш йилида Республика илмий журналларидаги (ОАК рўйхатидаги) илмий мақолалар ҳақида",
//   		code: "1.6.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институти профессор-ўқитувчилари ҳақида",
//   		code: "7",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Хорижий илмий тадқиқот марказлари грантлари ва хорижий илмий фондлари буюртмалари ҳисобига 2020 йилда олинган маблағлар бўйича",
//   		code: "1.7.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Соҳалар буюртмалари асосида ўтказилган илмий (илмий-ижодий) тадқиқотлардан 2020 йилда олинган молиявий маблағлар бўйича",
//   		code: "1.7.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Давлат грантлари асосида ўтказилган тадқиқотлардан 2020 йилда олинган маблағлар ҳақида",
//   		code: "1.7.3",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Рейтинги аниқланаётган йилда бажарилган илмий-тадқиқот ишларининг самарадорлиги ҳақида",
//   		code: "1.9.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг  рейтинги аниқланаётган йилда профессор-ўқитувчилари томонидан ихтиро, фойдали модел, саноат намуналари ва селекция ютуқлари учун олинган патентлар (тегишли ташкилотлар томонидан тасдиқланган норматив ҳужжатлар асосида) ҳақида",
//   		code: "1.9.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг  рейтинги аниқланаётган йилда профессор-ўқитувчилари томонидан ахборот-коммуникация технологияларига оид дастурлар ва электрон маълумотлар базалари учун олинган гувоҳномалар, муаллифлик ҳуқуқи билан ҳимоя қилинадиган турли материаллар ҳақида",
//   		code: "1.9.3",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг ҳисоб йилида олий таълим муассасаси профессор-ўқитувчилари томонидан ёзиб тайёрланган ва белгиланган тартибда рўйхатдан ўтказилган дарсликлар ҳақида",
//   		code: "2.2.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг ҳисоб йилида олий таълим муассасаси профессор-ўқитувчилари томонидан ёзиб тайёрланган ва белгиланган тартибда рўйхатдан ўтказилган ўқув қўлланмалар ҳақида",
//   		code: "2.2.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг рейтинги аниқланаётган йилда хорижий ўқитувчилар (мос равишда умумий сонга нисбатан % да) улуши ҳақидаги",
//   		code: "2.3.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг рейтинги аниқланаётган йилда хорижий талабалар (мос равишда умумий сонга нисбатан % да) улуши ҳақидаги",
//   		code: "2.3.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг ҳисоб йилида хорижий олий таълим муассасалари билан академик алмашув дастурлари (талабалар томонидан) ҳақида",
//   		code: "2.4.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг ҳисоб йилида хорижий олий таълим муассасалари билан халқаро конференциялар, семинарлар, илмий ёки ўқув лойиҳаларда талабалар ва ўқитувчилар иштирок этиши хақидаги",
//   		code: "2.4.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Таълим йўналишлари (мутахассисликлари) бўйича чет тилида ўқитиладиган фанлар салмоғи (мутахассислик фанларининг жами сонига нисбатан %да) ҳақида",
//   		code: "2.5",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Давлат гратнти асосида битирган битирувчиларнинг ишга жойлашганлиги (битирганидан сўнг 6 ой ичида) даражаси ҳақида",
//   		code: "3.2.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг Давлат гранти асосида битирган магистратура битирувчиларининг ишга жойлашганлик даражаси ҳақида",
//   		code: "3.2.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг рейтинг йилида халқаро олимпиадаларида, нуфузли танловлар совринли ўринларни қўлга киритган ҳамда мукофот (диплом)ларга сазовор бўлган талабалар салмоғи ҳақида",
//   		code: "3.4.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида профессор-ўқитувчиларнинг рейтинг йилида республика олимпиадаларида, нуфузли танловлар совринли ўринларни қўлга киритган ҳамда мукофот (диплом)ларга сазовор бўлган талабалар салмоғи ҳақида",
//   		code: "3.4.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Спорт клубига аъзо бўлиб, ташкил этилган спорт секцияларида жисмоний тарбия ва спорт билан мунтазам шуғулланувчи спорт таснифига (спортчи разрядлари) эга бўлган талабалар*",
//   		code: "4.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Олий таълим муассасасининг спорт моддий техник базаси билан таъминланганлиги",
//   		code: "4.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтида фаолият кўрсатаётган  профессор-ўқитувчилар тўғрисида умумий",
//   		code: "5",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтидаги бакалавриат таълим йўналишлари ва магистратура мутахассисликлари шифри, номи ва талабалари сони ҳақида",
//   		code: "6",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтидаги бакалавриат таълим йўналишлари ва магистратура  мутахассисликлари шифри, номи ва талабалари сони ҳақида",
//   		code: "6.1",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтидаги бакалавриат таълим йўналишлари ва магистратура мутахассисликлари шифри, номи ва талабалари сони ҳақида",
//   		code: "6.2",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     },
//   	{
//   		title: "Фарғона политехника институтидаги бакалавриат таълим йўналишлари ва магистратура мутахассисликлари шифри, номи ва талабалари сони ҳақида",
//   		code: "6.3",
//   	  link: "<a href='/pvorey/indexes/1d2'><h1>+</h1></a>"
//     }
//   ];

//   indexTitles = new BehaviorSubject<indexTitlesInt[]>(this.INDEXTITLES)

