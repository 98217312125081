import {Component, Output, EventEmitter, OnInit } from '@angular/core';
import { InjiService } from '../../shared/service/inji.service';
import firebase from "firebase/app";
import "firebase/database";
import { SimpleModalService } from 'ngx-simple-modal';
import { PromptComponent } from '../../shared/components/alert/prompt/prompt.component';

@Component({
    selector: 'reject-component',
    template: `
	    <div class="d-flex flex-nowrap text-white">
	      <button type="button" class="btn btn-info" (click)="reject()">
	        <i class="fa fa-ban"></i>
	      </button>
	    </div>
    `,
    styles: [`.d-flex{justify-content:center;}`]
})
export class RejectRenComponent implements OnInit  {

    rowData: any;


    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(
    	private InjiService: InjiService,
	    private SimpleModalService: SimpleModalService
    	){
    }
    ngOnInit(){
    }
    reject(){
	    this.SimpleModalService.addModal(PromptComponent, {
        title: 'Rad etish',
        question: 'Xabar'})
        .subscribe((message) => {
	        if (message) {
		      this.InjiService.updateRowSubjects.next({action:'rejectedRow', data: this.rowData, rejectedMessage: {who: 'ADMIN', message: message}});
	        }
        });
    }
}

	          // this.tmp.loadingRgba = true;
	          // this.dataService.pvoKaf$.subscribe(pvoData=>{
	          //    pvoData.forEach((pvo, ss, sss)=>{
	          //      var fullName = (pvo.lname + pvo.fname + (pvo.patronymic?pvo.patronymic:''));
	          //       var arr = this.tableMain.filter((res, ind)=> ind.toString() === source.added_id);
	          //       arr = arr[0];
	          //       arr[arr.length-1].status = "complete"
	          //       arr[arr.length-1]['grade'] = parseFloat(message);
	          //       if (event.data.source.pvoNames && (event.data.source.pvoNames).replace(/ /g,'') === fullName.replace(/ /g,'')) {
	          //         this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big", {path: `pvoIns/${pvo.added_id}/${source.indexId}`, grade: parseFloat(message)});
	          //         a = true;
	          //       }
	          //       if ((sss.length === (ss+1)) && !a) {
	          //         this.saveFormToDb(arr, source.indexId, source.added_id, "fieldsInform", "big");
	          //       }
	          //    })
	          // })