import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { BeingCheckedRoutingModule } from './being-checked-routing.module';
import { BeingCheckedComponent } from './being-checked.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RenderComponent } from './render.component';

@NgModule({
  declarations: [
    BeingCheckedComponent,
    RenderComponent
  ],
  imports: [
    BeingCheckedRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    Ng2SmartTableModule
  ],
  providers: [],
  bootstrap: [
  ],
  entryComponents: [
  ]
})
export class BeingCheckedModule { }
