// import {Component, Output, EventEmitter, OnInit } from '@angular/core';
// import { InjiService } from '../../../shared/service/inji.service';
// import firebase from "firebase/app";
// import "firebase/database";
// import { SimpleModalService } from 'ngx-simple-modal';
// import { ConfirmComponent } from '../../../shared/components/alert/confirm/confirm.component';
import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    selector: 'multi-sel-component',
    template: `
        <select multiple [(ngModel)]="yourModelStore">
          <option *ngFor="let item of myValues" [value]="item.value">{{item.name}}</option>
        </select>
    `,
    styles: [``]
})
export class MultiSelComponent implements OnInit  {

    rowData: any;
    @Input() value;

    yourModelStore: any;
    myValues: any

    constructor(){
        console.log('MultiSelComponent 1')

    }
    ngOnInit(){
        console.log('MultiSelComponent 2')
        this.myValues = ['aaa', 'ba', 'caaaa']
    }
}
// export class MultiSelComponent implements OnInit {


//   yourModelStore: any; // rendered as this.yourModelStore = ['value', 'value'];

//   ngOnInit() {
//     this.myValues = this.value;
//   }
// }