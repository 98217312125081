import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: "app-date",
  template: `
	        <div class="form-group row" [formGroup]="group">
		        <label for="validationCustom{{random}}" class="col-xl-3 col-md-4">
		        	<span *ngFor="let validation of field.validations;">
		        		<span *ngIf="validation.name === 'required'">*</span>
		        	</span>
		        	{{field.label}}
		        </label>
		        <div class="col-md-8">
			         <div class="input-group input-grp-p">
                        <input class="datepicker-here form-control digits" ngbDatepicker
                            [formControlName]="field.name" #f="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                    class="fa fa-calendar"></i></button>
                        </div>
                    </div>
					<ng-container *ngFor="let validation of field.validations;">
				       	<div style="color:red;" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">
					      {{validation.message}}
					    </div>
					</ng-container>
		        </div>
		    </div>
`,
  styles: []
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  public model: NgbDateStruct;
  public modelFooter: NgbDateStruct;
  random: number;

  constructor(private calendar: NgbCalendar) {}
  ngOnInit() {this.random = Math.floor(Math.random() * 100);}
  selectToday() {
    this.model = this.calendar.getToday();
  }
}

					// <input matInput class="form-control" [matDatepicker]="picker" [formControlName]="field.name" [placeholder]="field.label">
					// <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					// <mat-datepicker #picker></mat-datepicker>
					// <mat-hint></mat-hint>