import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from '../../shared/service/firebase.service';
import { AuthService } from '../../shared/service/auth.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import firebase from "firebase/app";
import "firebase/database";

interface Files{
    ichtfile: File[];
    tashtfile: File[];
    kybfile: File[];
    fybfile: File[];
    uslfile: File[];
}
@Component({
  selector: 'app-add-handbook',
  templateUrl: './add-handbook.component.html',
  styleUrls: ['./add-handbook.component.scss'],
})

export class AddHandbookComponent {
  public accountForm: FormGroup;
  
  tmp = {
    error: "", 
    loading: false, 
    lastAdded_Id: '',  
    fileError: '', 
    loadingFile: {
      loadingFile: false,
      progressFile: 0,
      progressFileAll: 0,
      filesLength: 0
    },

  };
  user;
  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseService,
    private authService: AuthService
  ) {
    authService.user.subscribe(user=>{
      this.user = user;
    })
    this.createAccountForm();

  }
  files:Files = {
    ichtfile: [],
    tashtfile: [],
    kybfile: [],
    fybfile: [],
    uslfile: [],
  };
  toggleHover(e){}
  onSelectFile(event, type) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason==='type') {
           this.tmp.fileError = 'fayl turlarini yuklash mumkin: ' + ((type==='uslf')?'word':'pdf');
           return;
        }
        if (event.rejectedFiles[0].reason==='size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files[type].push(...event.addedFiles);
      this.accountForm.controls[type].setValue(this.files[type]);
  }
  onRemoveFile(event, type) {
      this.files[type].splice(this.files[type].indexOf(event), 1);
      this.accountForm.controls[type].setValue(this.files[type]);
   }
  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      ishnmu: [null, Validators.required],
      ishlt: [null],
      ishln: [null],
      ishh: [null],
      icht: [null],
      ichtfile: [null],
      tasht: [null],
      tashtfile: [null],
      rbrt: [null],
      kybn: [null],
      kybs: [null],
      kybfile: [null],
      fybn: [null],
      fybs: [null],
      fybfile: [null],
      xau: [null],
      uslfile: [null, Validators.required],
      created: firebase.database.ServerValue.TIMESTAMP
    })
  }
  buildingFloorNumber = 1;

  incrementNumber(type){
    const value = this.accountForm.value[`${type}`];
    if (value<1) {
      this.accountForm.value[`${type}`] = 1;
    }
      this.accountForm.controls[`${type}`].setValue(this.accountForm.value[`${type}`]);
  }
  increment(type) {
    // console.log(type, this.accountForm.value[`${type}`]++)
    // this.buildingFloorNumber += 1;
      // console.log(this.accountForm.get(this.field.name))

      this.accountForm.controls[`${type}`].setValue(this.accountForm.value[`${type}`]+1);
    // (this.accountForm.get('buildingFloorNumber').value) = (1/this.buildingFloorNumber); 
  }

  decrement(type) {
    if (this.accountForm.value[`${type}`] > 1) {
      // this.buildingFloorNumber -= 1;
      this.accountForm.controls[`${type}`].setValue(this.accountForm.value[`${type}`]-1);
      // (<Control>this.group.get(this.field.name).value) = (1/this.counter); 
    }
  }
  

  ngOnInit() {
  }
  get f(){
    return this.accountForm.controls;
  }
  onSubmit() {
    // console.log(this.accountForm)
    // if (this.accountForm.value.uslf) {
    this.tmp.loadingFile.filesLength = 0;
    Object.keys(this.files).forEach(key=>{
      if (this.accountForm.value[key]) {
        this.tmp.loadingFile.filesLength += 1;
      }
    })
    this.tmp.loadingFile.loadingFile = true;
    this.startUploadFiles(Object.keys(this.files), 0);
    // }else{
    //   // this.tmp.loading = false;
    //   this.tmp.fileError = 'Faylni yuklang';
    //   return;
    //   // this.saveFormToDb(this.accountForm.value);
    //   // this.addingArray([],  fieldValue);
    // }
  }
  startUploadFiles(fileKeys, fileIdx){
    if (fileKeys.length === fileIdx) {
      this.tmp.loadingFile.loadingFile = false;
      this.tmp.loading = true;
      this.saveFormToDb(this.accountForm.value);
      console.log("loading file complete")
      return;
    }
    if (this.accountForm.value[fileKeys[fileIdx]]) {
      console.log('monitorUpload')
      this.monitorUpload(this.accountForm.value[fileKeys[fileIdx]], 0, [], fileKeys, fileIdx);
    }else{
      console.log('idx + 1 ')
      this.startUploadFiles(fileKeys, (fileIdx+1))
    }
  }
  fileType(name){
    if (name.indexOf('jpeg') !== -1) {
      return ".jpeg" 
    }else if(name.indexOf('jpg') !== -1){
      return ".jpg"
    }else if(name.indexOf('png') !== -1){
      return ".png"
    }else if(name.indexOf('pdf') !== -1){
      return ".pdf"
    }else if(name.indexOf('docx') !== -1){
      return ".docx"
    }else if(name.indexOf('doc') !== -1){
      return ".doc"
    }else{
      return "";
    }
  }
  monitorUpload(files, index, filesUrl, fileKeys, fileIdx) {
    var fileItem = files[index];
    const storageRef = firebase.storage().ref();
    var random = '_' + (Math.floor(Math.random()*1000)).toString();
    var fileSaveName = replaceAll((fileItem.name + random), ".", "")+this.fileType(fileItem.name);

    // [STATE_CHANGEDRT storage_upload_handle_error]
    // Create the file metadata
    var metadata = {
      contentType: fileItem.type
    };
    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child('ouq/'+ 'handbooks' + '/' + fileSaveName).put(fileItem, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.tmp.loadingFile.progressFile = progress/(files.length*this.tmp.loadingFile.filesLength);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      }, 
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            console.log('storage/unauthorized')
            break;
          case 'storage/canceled':
            console.log('storage/canceled')
            // User canceled the upload
            break;
          case 'storage/unknown':
            console.log('storage/unknown');
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.tmp.loadingFile.progressFileAll += this.tmp.loadingFile.progressFile;
          filesUrl.push({name: fileSaveName, url: downloadURL})
          if (files.length === (index+1)) {
            this.accountForm.value[fileKeys[fileIdx]] = filesUrl;
            this.startUploadFiles(fileKeys, (fileIdx+1))
          }else{
            this.monitorUpload(files, (index+1), filesUrl, fileKeys, fileIdx);
          }
        });
      }
    );
    // [END storage_upload_handle_error]
  }
  saveFormToDb(arr){
        if (arr['kybs']) {
          arr['kybs'] = arr['kybs']['day'] + '/' + arr['kybs']['month'] + '/' + arr['kybs']['year'];
        }
        if (arr['fybs']) {
          arr['fybs'] = arr['fybs']['day'] + '/' + arr['fybs']['month'] + '/' + arr['fybs']['year'];
        }
        if (!this.user) {
          return;
        }
        arr['user'] = this.user.email;
        arr['token'] = this.user.id;
        this.firebaseService.incDb('ouq/lastId/handbooks')
         .then((res)=>{
          this.firebaseService.getDoc('ouq/lastId/handbooks').then((snapshot) => {
              if (snapshot.exists()) {
                arr['added_id'] = snapshot.val().last_id.toString();
                arr['status'] = 'PVO';
                this.firebaseService.setDoc('/ouq/handbooks/beingchecked/'+arr['token']+'/'+snapshot.val().last_id.toString(), arr)
                .then(()=>{
                  this.tmp.loading = false;
                  alert('Ma\'lumotlar muvaffaqiyatli saqlandi!')
                  window.location.reload();
                }).catch(error=>{
                   alert('#504 ' + error);
                });
              }else{
                alert("#501 No data available");
              }
          }).catch((error) => {
             console.log('#502 ' + error);
          });
        }).catch(error =>{
          alert('#503 ' + error)
        });
  }
}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;  
      }
      n += g;
    }
    return n;
  }