import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/database";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  getDoc(ref){
    return firebase.database().ref(ref).get();
  }
  getColl(coll){
    return firebase.firestore().collection(coll).get();
  }
  updateStore(coll, doc, newObj){
    return firebase.firestore().collection(coll).doc(doc).update(newObj);
  }
  setDocStore(coll, doc, obj){
    return firebase.firestore().collection(coll).doc(doc).set(obj);
  }
  updateDoc(ref, obj){
    return firebase.database().ref(ref).update(obj);
  }
  incDb(path){
  	return firebase.database().ref(path).update({last_id: firebase.database.ServerValue.increment(1)});
  }
  incDbValue(path, value: number){
    return firebase.database().ref(path).update({grade: firebase.database.ServerValue.increment(value)});
  }
  setDoc(path, object) {
    return firebase.database().ref(path).set(object);
  }
  removeDoc(path) {
    return firebase.database().ref(path).remove();
  }
}
