import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { KafedraRoutingModule } from './kafedra-routing.module';
import { KafedraComponent } from './kafedra.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UpdateFileRenComponent } from './update-file-ren.component';
import { RejectRenComponent } from './reject.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    KafedraComponent,
    UpdateFileRenComponent,
    RejectRenComponent
  ],
  imports: [
    KafedraRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
  ],
  providers: [NgbActiveModal],
  bootstrap: [
  ],
  entryComponents: [
  ]
})
export class KafedraModule { }
