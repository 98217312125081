import {Component, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'custom-component',
    template: `
    	<div class="wrapper">
			<ul class="StepProgress">
			  <li class="StepProgress-item is-done"><strong>Arizangiz yuborildi.</strong>
			  	Arizangiz ko'rib chiqish uchun yuborildi.
			  </li>
			  <li class="StepProgress-item" [ngClass]="rowData.status === 'PVO'?'current':'is-done'"><strong>Noshirlik</strong>
			    Ishlamaning imlo xatolarsiz, orfografik, gramatik, chizma va rasmlarni to'g'riligi va belgilangan talablarga mosligi 
			  </li>
			  <li class="StepProgress-item" [ngClass]="rowData.status === 'PUBLISHINGDEP'?'current':(rowData.status === 'PVO'?'':'is-done')"><strong>Fakultet uslubiy kengash raisi</strong>
			  	OO'MTVning 01.03.2017y.dagi №107-buyrig'i talablariga mosligi 
			  </li>
			  <li class="StepProgress-item" [ngClass]="rowData.status === 'KAFEDRA'?'current':(rowData.status === 'PVO' || rowData.status === 'PUBLISHINGDEP'?'':'is-done')"><strong>Insitut uslubiy kengash ma'suli</strong></li>
			</ul>
		</div>
    `,
    styles: [`
		.wrapper {
		  width: 330px;
		  font-family: 'Helvetica';
		  font-size: 14px;
		}

		.StepProgress {
		  position: relative;
		  padding-left: 45px;
		  list-style: none;
		}
		.StepProgress::before {
		  display: inline-block;
		  content: '';
		  position: absolute;
		  top: 0;
		  left: 15px;
		  width: 10px;
		  height: 100%;
		  border-left: 2px solid #007bff;
		}
		.StepProgress-item {
		  position: relative;
		  counter-increment: list;
		}
		.StepProgress-item:not(:last-child) {
		  padding-bottom: 20px;
		}
		.StepProgress-item::before {
		  display: inline-block;
		  content: '';
		  position: absolute;
		  left: -30px;
		  height: 100%;
		  width: 10px;
		}
		.StepProgress-item::after {
		  content: '';
		  display: inline-block;
		  position: absolute;
		  top: 0;
		  left: -39px;
		  width: 20px;
		  height: 20px;
		  border: 2px solid #007bff;
		  border-radius: 50%;
		  background-color: #FFF;
		}
		.StepProgress-item.is-done::before {
		  border-left: 2px solid #007bff;
		}
		.StepProgress-item.is-done::after {
		  content: "✔";
		  font-size: 10px;
		  color: #FFF;
		  text-align: center;
		  border: 0px solid #007bff;
		  background-color: #007bff;
		}
		.StepProgress-item.current::before {
		  border-left: 2px solid #007bff;
		}
		.StepProgress-item.current::after {
		  content: counter(list);
		  text-align: center;
		  color: #007bff;
		  border: 2px solid #007bff;
		  background-color: white;
		  padding-top: 0px;
		  width: 26px;
		  height: 26px;
		  top: -6px;
		  left: -42px;
		  font-size: 14px;
		}
		.StepProgress strong {
		  display: block;
		}`]
})
export class RenderComponent implements OnInit {

    rowData: any;

   @Output() save: EventEmitter<any> = new EventEmitter();

   constructor(){
   }
   ngOnInit(){
   }
    onModelChange(table) {
        this.rowData.total = this.rowData.amount * this.rowData.price;
        this.save.emit(this.rowData);
    }
}